import type { AllHTMLAttributes } from 'react';

import {
  BannerPlacement,
  BannerWrap,
  BannerBox,
} from './Banner.styled';

export interface BannerProps extends AllHTMLAttributes<HTMLDivElement> {
  show?: boolean;
  placement?: BannerPlacement;
}

export function Banner({
  show,
  placement = BannerPlacement.topLeft,
  children,
}: BannerProps) {
  return (
    <BannerWrap
      show={show}
      placement={placement}
    >
      <BannerBox
        show={show}
        placement={placement}
      >
        {children}
      </BannerBox>
    </BannerWrap>
  );
}
