import { ConfirmationScreen } from 'apps/kiosk/components/Onboarding/ConfirmationScreen';

type LinkSentProps = {
  phoneNumber: string;
  onDismiss: () => void;
  onHelpClick: () => void;
};

export function LinkSent({
  phoneNumber,
  onDismiss,
  onHelpClick,
}: LinkSentProps) {
  return (
    <ConfirmationScreen
      heading={(
        <>
          <span>Remember your license plate</span>
          <br />
          <span>before you walk away.</span>
        </>
      )}
      message={(
        <>
          <p>
            This parking facility uses pay-by-plate technology.
            You&apos;ll need your license plate to pay for parking.
          </p>
          <p>
            We&apos;ve texted you a link. Tap the link to start parking.
          </p>
        </>
      )}
      helpMessage={<>I didn&apos;t get a text</>}
      onHelpClick={onHelpClick}
      onDismiss={onDismiss}
    />
  );
}
