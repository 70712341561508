import { useEffect } from 'react';

import storage from 'local-storage-fallback';
import { useHistory } from 'react-router-dom';

import { PageBox } from 'apps/kiosk/components';
import { ONBOARDING_UUID_STORAGE_KEY } from 'apps/kiosk/constants';

import { Message } from './MissingUUIDContainer.styled';

export function MissingUUIDContainer() {
  const history = useHistory();

  useEffect(() => {
    const lastSetUUID = storage.getItem(ONBOARDING_UUID_STORAGE_KEY);
    if (lastSetUUID) {
      history.replace(`/${lastSetUUID}`);
    }
  }, [history]);

  return (
    <PageBox>
      <Message>
        This kiosk is temporarily out of order.
      </Message>
    </PageBox>
  );
}
