
import {
  Box,
  Text,
} from '@m/components';

import { ReactComponent as ExitIcon } from 'apps/kiosk/assets/svg/car-exit.svg';
import { ReactComponent as DismissIcon } from 'apps/kiosk/assets/svg/dismiss.svg';
import { ReactComponent as PlateIcon } from 'apps/kiosk/assets/svg/plate.svg';
import { ReactComponent as QrScanIcon } from 'apps/kiosk/assets/svg/qr-scan.svg';
import { AlertModal } from 'apps/kiosk/components/Modals/AlertModal';
import {
  ModalHeadingLarge,
  ModalButton,
  CloseButton,
  CloseButtonContentLarge,
} from 'apps/kiosk/components/Modals/Modals.styled';

import { FeatureInfo } from './FeatureInfo';

type HowThisWorksProps = {
  show?: boolean;
  onDismiss?: () => void;
};

export function HowThisWorks({
  show,
  onDismiss,
}: HowThisWorksProps) {
  return (
    <AlertModal
      show={show}
      onCancel={onDismiss}
      width="613px !important"
    >
      <Box
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        marginBottom="50px"
        marginTop="-8px"
      >
        <ModalHeadingLarge
          fontSize="32px !important"
          letterSpacing="0.03em"
          marginBottom={0}
          lineHeight="48px !important"
        >
          Never wait in line again
        </ModalHeadingLarge>
        <CloseButton
          position="relative"
          right="-16px"
          onClick={onDismiss}
        >
          <CloseButtonContentLarge>
            <DismissIcon
              fill="currentColor"
              width={14}
              height={14}
            />
          </CloseButtonContentLarge>
        </CloseButton>
      </Box>

      <FeatureInfo
        icon={<QrScanIcon />}
        heading="Signing up is easy"
        content="Just scan a code on a sign or enter your phone number at a kiosk to start parking. No need to download an app."
      />
      <FeatureInfo
        icon={<PlateIcon />}
        heading="Completely contactless"
        content="Pay by license plate. Just add your vehicle and payment method once for contactless payment forever."
      />
      <FeatureInfo
        icon={<ExitIcon />}
        heading="Just drive in and drive out"
        content={`
          When your car enters and exits, you will receive a text message.
          Drive out and your account will automatically be charged for the duration of your visit.*
        `}
      />
      <Box
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Text
          as="p"
          fontSize="12px"
          lineHeight={1.35}
          marginTop="4px"
          marginBottom="12px"
          opacity={0.6}
        >
          This parking facility uses pay-by-plate technology.
          Each time your vehicle drives through the entrance and exit,
          a camera takes a photo of your license plate and records a
          timestamp to facilitate the visit.
        </Text>
        <Text
          as="p"
          fontSize="12px"
          lineHeight={1.35}
          marginTop="0"
          marginBottom="40px"
          opacity={0.6}
        >
          *Inclusive of any credits, promotions, validations and/or fees.
          Your account is automatically charged after your drive out
        </Text>
      </Box>
      <ModalButton
        onClick={onDismiss}
        backgroundColor="blue6"
        color="grey1"
      >
        Got it
      </ModalButton>
    </AlertModal>
  );
}
