import type { ReactNode } from 'react';

import type { StyledSystemProps } from '@m/theme';

import {
  PageRoot,
  PageBg,
  PageContent,
} from './PageBox.styled';

interface LandingProps extends StyledSystemProps {
  children?: ReactNode;
}

export function PageBox({
  children,
  ...pageRootProps
}: LandingProps) {
  return (
    <PageRoot {...pageRootProps}>
      <PageBg />
      <PageContent>{children}</PageContent>
    </PageRoot>
  );
}
