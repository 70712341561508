import styled, { css } from 'styled-components';

import { withStyledSystem } from '@m/theme';
import type { StyledSystemProps } from '@m/theme';

import appImgSrc from 'apps/kiosk/assets/img/iphone-app.png';
import welcomeImgSrc from 'apps/kiosk/assets/img/palm-tree-sky.jpg';

const headingStyle = css`
  font-size: 32px;
  font-weight: 700;
  text-indent: 2px;
  margin: 0;
  line-height: 1;

  @media (min-width: 700px) {
    font-size: 40px;
  }
`;

export const Heading = styled.h1<StyledSystemProps>`
  ${headingStyle}
  margin-top: 24px;
  color: ${({ theme }) => (theme.colors.grey7)};

  @media (min-height: 700px) {
    margin-top: 32px;
  }

  ${withStyledSystem}
`;

export const SubHeading = styled.h2<StyledSystemProps>`
  ${headingStyle}
  margin-top: 8px;
  margin-bottom: 60px;
  max-width: 557px;
  white-space: nowrap;
  overflow: visible;
  color: ${({ theme }) => (theme.colors.blue6)};
  ${withStyledSystem}
`;

export const WelcomeImage = styled.div`
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 570px;
  max-height: 743px;
  border-radius: 24px;
  background-color: #fff;
  background-image: url(${welcomeImgSrc});
  background-size: auto 163.5%;
  background-position: 50.5% 61.5%;
`;

export const AppImage = styled.div`
  display: block;
  position: relative;
  z-index: 1;
  width: 600px;
  height: 940px;
  margin-right: -173px;
  margin-bottom: -370px;
  background-image: url(${appImgSrc});
  background-size: contain;
  background-position: 0 0;
`;

export const SiteInfo = styled.div`
  position: absolute;
  top: 203px;
  left: 86px;
  width: 258px;
  height: 48px;
`;

export const SiteName = styled.div`
  width: 100%;
  height: 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.025em;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SiteAddress = styled.div`
  width: 100%;
  height: 13px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: normal;
  color: #797979;
  margin-top: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
