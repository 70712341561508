import styled from 'styled-components';

import { ReactComponent as LoadingIcon } from 'apps/kiosk/assets/svg/loading.svg';
import {
  pulse,
  spin,
} from 'styled/keyframes';

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  user-select: none;
  flex: 1;
`;

export const PhoneValue = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: left;
  position: relative;
  width: 340px;
  height: 80px;
  margin-top: 8px;
  padding-left: 56px;
  box-shadow: 0 0 0 1px ${({ theme, isLoading }) => (isLoading ? 'rgba(0,0,0,0.8)' : theme.colors.blue6)};
  border-radius: 4px;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 0;
  color: ${({ theme }) => (theme.colors.grey7)};
  user-select: none;
  transition: box-shadow 200ms linear 0ms;
`;

export const PhonePlaceholder = styled.div`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 0;
  color: #8e8e8e;
  user-select: none;
`;

export const InputCursor = styled.div<{ error?: boolean, isLoading?: boolean; }>`
  position: relative;
  width: 2px;
  height: 34px;
  margin-left: 2px;
  transition: opacity 100ms linear 0ms;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  color: ${({ theme }) => (theme.colors.blue6)};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 1.5px;
    animation: ${pulse} 1200ms ease-in-out 0ms infinite;
    animation-play-state: ${({ isLoading }) => (isLoading ? 'paused' : 'running')};
  }
`;

export const LoadingSpinner = styled(LoadingIcon)`
  animation: ${spin} 850ms linear 0ms infinite;
`;
