import type { ReactNode } from 'react';

import type { BoxProps } from '@m/components';
import type { StyledSystemProps } from '@m/theme';

import { ReactComponent as DismissIcon } from 'apps/kiosk/assets/svg/dismiss.svg';

import {
  ModalBackground,
  CloseButton,
  CloseButtonContent,
} from './Modals.styled';
import {
  SlideUpModalBox,
  SlideUpModalContent,
} from './SlideUpModal.styled';

interface SlideUpModalProps extends BoxProps {
  show?: boolean;
  variant?: 'sheet' | 'overlay',
  onCancel?: () => void;
  hideCloseButton?: boolean,
  boxProps?: StyledSystemProps
  children?: ReactNode;
}

export function SlideUpModal({
  show = false,
  variant = 'sheet',
  onCancel = () => {},
  hideCloseButton,
  boxProps,
  children,
  ...contentProps
}: SlideUpModalProps) {
  return (
    <SlideUpModalBox
      {...boxProps}
      show={show}
      variant={variant}
    >
      <ModalBackground onClick={onCancel} />
      <SlideUpModalContent
        {...contentProps}
        show={show}
        variant={variant}
      >
        {children}
        <CloseButton
          onClick={onCancel}
          hide={hideCloseButton}
        >
          <CloseButtonContent>
            <DismissIcon />
          </CloseButtonContent>
        </CloseButton>
      </SlideUpModalContent>
    </SlideUpModalBox>
  );
}
