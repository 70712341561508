import {
  useEffect,
  useRef,
} from 'react';
import type { SyntheticEvent } from 'react';

import Keyboard from 'react-simple-keyboard';

import { KeyboardStyler } from './PhoneKeyboard.styled';

import type { KeyboardReactInterface } from 'react-simple-keyboard';

type PhoneKeyboardProps = KeyboardReactInterface['options'] & {
  active?: boolean;
  submitEnabled?: boolean;
  onChange?: (value: string) => void;
  onKeyPress?: (key: string, e: SyntheticEvent) => void;
};

const defaultDisplay = {
  '{backspace}': '⌫',
};

const defaultLayout = {
  default: [
    '1 2 3',
    '4 5 6',
    '7 8 9',
    '0 {backspace}',
  ],
};
export function PhoneKeyboard({
  active,
  submitEnabled,
  ...keyboardProps
}: PhoneKeyboardProps) {
  const keyboardRef = useRef<KeyboardReactInterface>();

  // remove keyboard event listeners on unmount
  // @ts-ignore
  useEffect(() => () => keyboardRef.current?.destroy(), []);

  return (
    <KeyboardStyler>
      <Keyboard
        keyboardRef={(kb: KeyboardReactInterface) => {
          keyboardRef.current = kb;
        }}
        preventMouseUpDefault
        autoUseTouchEvents={false}
        enableLayoutCandidates={false}
        disableButtonHold
        disableCaretPositioning
        stopMouseUpPropagation
        stopMouseDownPropagation
        physicalKeyboardHighlight
        physicalKeyboardHighlightPress
        layout={defaultLayout}
        display={defaultDisplay}
        {...keyboardProps}
      />
    </KeyboardStyler>
  );
}
