import type { ReactNode } from 'react';

import type { BoxProps } from '@metropolis-io/components';

import {
  Box,
  Text,
  TextBold,
} from '@m/components';

interface FeatureInfoProps extends BoxProps {
  icon: ReactNode;
  heading: ReactNode;
  content: ReactNode;
}
export function FeatureInfo({
  icon,
  heading,
  content,
}: FeatureInfoProps) {
  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      marginBottom="28px"
    >
      <Box
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        maxWidth="44px"
        height="40px"
        flexShrink={0}
        flexGrow={0}
        marginRight="32px"
      >
        {icon}
      </Box>
      <Box
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        flex={1}
        fontSize="18px"
        color="#000"
        marginTop="4px"
      >
        <TextBold
          marginBottom="8px"
        >
          {heading}
        </TextBold>
        <Text
          fontWeight={400}
          lineHeight={1.35}
        >
          {content}
        </Text>
      </Box>
    </Box>
  );
}
