
import { ReactComponent as CheckCirle } from 'apps/kiosk/assets/svg/check-circle-outline.svg';
import { ConfirmationScreen } from 'apps/kiosk/components/Onboarding/ConfirmationScreen';

type LinkSentProps = {
  phoneNumber: string;
  onDismiss: () => void;
};

export function AccountExists({
  phoneNumber,
  onDismiss,
}: LinkSentProps) {
  return (
    <ConfirmationScreen
      icon={<CheckCirle />}
      heading="Looks like you already have a Metropolis account."
      message={(
        <>
          We&apos;ve sent a text to your number with a link to manage your parking visit.
        </>
      )}
      onDismiss={onDismiss}
      paddingBottom="48px"
    />
  );
}
