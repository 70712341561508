import styled from 'styled-components';

export const Message = styled.h1`
  font-size: 32px;
  font-weight: 700;
  text-indent: 2px;
  margin: 0;
  line-height: 1;
  color: ${({ theme }) => (theme.colors.grey7)};
  opacity: 0.6;
`;
