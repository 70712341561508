import styled from 'styled-components';

import { withStyledSystem } from '@m/theme';
import type { StyledSystemProps } from '@m/theme';

export type StyledScrollingModalProps = StyledSystemProps & {
  show: boolean;
};

export const ScrollingModalBox = styled.div<StyledScrollingModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: ${({ show }) => (show ? 0 : 200)}ms;
  ${withStyledSystem}
`;

function getTransition({
  show,
}: StyledScrollingModalProps) {
  const baseDuration = show ? 400 : 500;
  const delay = show ? 200 : 50;

  return `
    transform ${baseDuration}ms ease ${delay}ms,
    opacity ${show ? baseDuration : baseDuration / 2}ms ease ${delay}ms`;
}
export const ScrollingModalContent = styled.div<StyledScrollingModalProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 708px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  overflow: hidden;
  pointer-events: inherit;
  user-select: none;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: translate3d(
    0,
    ${({ show }) => (show ? '0' : '100%')},
    0
  );
  transition: ${getTransition};
  ${withStyledSystem}
`;

export const ScrollingModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding-left: 32px;
  background-color: ${({ theme }) => (theme.colors.grey1)};
  width: 660px;
  max-width: 100%;
  height: 96px;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const ScrollingModalHeading = styled.h3<StyledScrollingModalProps>`
  font-size: 32px;
  ${withStyledSystem}
`;

export const ScrollingModalScrollArea = styled.div<StyledScrollingModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  width: 100%;
  margin-top: -64px;
  padding-top: 64px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: auto;
  padding-bottom: 32px;
  ${withStyledSystem}
`;

export const ScrollContentBox = styled.div<StyledScrollingModalProps>`
  width: 100%;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  padding: 32px;
  padding-top: 0;
  background-color: ${({ theme }) => (theme.colors.grey1)};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08),
              0 2px 24px rgba(0, 0, 0, 0.08);
  ${withStyledSystem}
`;
