import { getConfig } from '@metropolis-io/config';

const {
  CONFIG_ENV,
} = getConfig();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isLocal = CONFIG_ENV === 'local' || window.location.hostname === 'localhost';

// running app locally, but using development API
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isLocalDev = CONFIG_ENV === 'development' && window.location.hostname === 'localhost';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isDevelopment = CONFIG_ENV === 'development' && window.location.hostname !== 'localhost';

// running app locally, but using staging API
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isLocalStaging = CONFIG_ENV === 'staging' && window.location.hostname === 'localhost';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isStaging = CONFIG_ENV === 'staging' && window.location.hostname !== 'localhost';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isProd = CONFIG_ENV === 'production' && window.location.hostname !== 'localhost';

// connect to external API by Setting to true and defining the api env bellow
export const CONNECT_TO_EXTERNAL_API = !!process.env.REACT_APP_CONFIG_USE_EXTERNAL_API;

export const FREE_PERIOD_CUSTOMER_DISPLAY_ENABLED = false;
export const ADMIN_RATE_SCHEDULES_ENABLED = true;
export const ADMIN_DISABLE_SUB_CANCEL_REASON = isLocal;
export const ENABLE_ENT_PAYMENT_REMOVAL = true;
export const ENABLE_PENDING_MENU_NOTIFICATIONS = false;
export const ENABLE_INTAKE_PARTNER_SELECTION = !isProd;
export const ADMIN_LANE_DELETE_ENABLED = isLocal;
export const ADMIN_VIOLATION_OPERATOR_FILTER_ENABLED = false;
export const ADMIN_ALLOW_DELETE_GATES = isDevelopment || isLocal;
export const ADMIN_ALLOW_MULTI_SITE_ENTERPRISE_SUBS = true;
export const ADMIN_PARKING_PASS_LOCATIONS = false;
export const ADMIN_VALIDATION_V2 = isLocal || isDevelopment;
export const ENABLE_RUM = false;
export const ENABLE_RUM_CUSTOMER_ONLY = true;
export const SPECIALIST_VALET_TIMER = false;
export const HIDE_NEW_VEND_GATE = isProd || isStaging;
export const ADMIN_PORTAL_ACH = isLocal || isDevelopment;
