import {
  useCallback,
  useState,
} from 'react';

import { Text } from '@m/components';

import { ModalHeading } from 'apps/kiosk/components';
import { PhoneForm } from 'apps/kiosk/components/Onboarding';
import { OnboardingService } from 'apps/kiosk/services';
import { heapTrackProperties } from 'utils/heap';

import {
  InputContainerBox,
  ErrorMessage,
} from './PhoneInputContainer.styled';

type PhoneInputContainerProps = {
  siteUUID: string,
  completed?: boolean;
  onLinkSent: (phoneNumber: string) => void;
  onAccountExits: (phoneNumber: string) => void;
};

export function PhoneInputContainer({
  siteUUID,
  completed,
  onLinkSent,
  onAccountExits,
}: PhoneInputContainerProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    const {
      success,
      data,
    } = await OnboardingService.userSignup({
      uuid: siteUUID,
      phoneNumber,
    });

    setError(false);
    setLoading(false);

    if (success) {
      const { user } = data;
      if (user?.isRegistered) {
        onAccountExits(phoneNumber);
        heapTrackProperties('Sent: Onboarding Link', {
          userId: user.id,
          newUser: 'no',
          'Date/Time (UTC)': (new Date()).toUTCString(),
          timestamp: Date.now(),
        });
      } else if (user) {
        onLinkSent(phoneNumber);
        heapTrackProperties('Sent: Onboarding Link', {
          userId: user.id,
          newUser: 'yes',
          'Date/Time (UTC)': (new Date()).toUTCString(),
          timestamp: Date.now(),
        });
      } else {
        setError(true);
        heapTrackProperties('Error: Unable to send onboarding link', {
          'Date/Time (UTC)': (new Date()).toUTCString(),
          timestamp: Date.now(),
        });
      }
    } else {
      setError(true);
      heapTrackProperties('Error: Unable to send onboarding link', {
        'Date/Time (UTC)': (new Date()).toUTCString(),
        timestamp: Date.now(),
      });
    }
  }, [
    onAccountExits,
    onLinkSent,
    siteUUID,
  ]);

  return (
    <InputContainerBox completed={completed}>
      {error && (
        <ErrorMessage>
          There was a problem sending your request.
          <br />
          Please try again in a few seconds.
        </ErrorMessage>
      )}
      <ModalHeading
        fontSize="32px"
        textAlign="center"
        lineHeight={1.2}
        color="grey7"
      >
        Enter your phone number
      </ModalHeading>
      <Text
        as="p"
        fontSize="22px"
        fontWeight={400}
        lineHeight={1.5}
        color="rgba(0,0,0,0.6)"
        maxWidth="300px"
        textAlign="center"
        marginTop="8px"
        marginBottom="8px"
      >
        We&apos;ll send you a text to sign up and start parking
      </Text>
      <PhoneForm
        loading={loading}
        submitted={completed && error === false}
        onSubmit={handleSubmit}
        error={error}
      />
    </InputContainerBox>
  );
}
