import {
  Switch,
  Route,
} from 'react-router-dom';

import { LandingContainer } from 'apps/kiosk/containers/Onboarding/LandingContainer';
import { MissingUUIDContainer } from 'apps/kiosk/containers/Onboarding/MissingUUIDContainer';

export default function OnboardingKiosk() {
  return (
    <Switch>
      <Route exact path="/">
        <MissingUUIDContainer />
      </Route>
      <Route path="/:uuid">
        <LandingContainer />
      </Route>
    </Switch>
  );
}
