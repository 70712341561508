/* eslint-disable max-len */
/**
 * Reads in environment variables here
 *
 * Since our app isn't ready to move everything to environment variables,
 * we need to compute some of the client and API base URLs for different domains
 * e.g. localhost:####, app.dev.metrop.io, app.metropolis.io
 *
 * Next phase, we should retrieve all client and site API urls from environment variables and remove the reliance of the utility functions
 */
import { getConfig } from '@metropolis-io/config';

import {
  isLocal,
  isLocalDev,
  isLocalStaging,
} from 'constants/FeatureFlags';

// TODO: convert all configs from uikit to env vars
const {
  CONFIG_ENV,
  SITE_API_BASE_URL: UIKIT_SITE_API_BASE_URL,
} = getConfig();

export type AppSubdomain = 'admin' | 'app' | 'customer' | 'specialist' | 'pay' | 'portal' | 'intake' | 'validation' | 'onboarding';

const METROP_URL_MATCH = /^[a-z\d]+\.[a-z]+\.metrop.io$/;
const isMetropEnv = window.location.host.match(METROP_URL_MATCH);

/**
 * Only for non-prod and non-local environments. We'll need this until we support env vars for all envs
 * Helps for routing to another client app
 *
 * Usage: const redirectUrl = `app${getAppBaseDomain()}`;
 */
function getAppUrl(appSubdomain: AppSubdomain) {
  if (isMetropEnv) {
    const splitHost = window.location.host.split('.');
    return `https://${appSubdomain}.${splitHost.splice(1).join('.')}`;
  }

  if (CONFIG_ENV === 'staging') {
    return `https://${appSubdomain}-staging.metropolis.io`;
  }

  if (CONFIG_ENV === 'development') {
    return `https://${appSubdomain}-development.metropolis.io`;
  }

  return `https://${appSubdomain}.metropolis.io`;
}

export function getAppBaseUrl(appSubdomain: AppSubdomain) {
  if (appSubdomain === 'admin') return ADMIN_APP_URL;
  if (appSubdomain === 'app' || appSubdomain === 'customer') return CUSTOMER_APP_URL;
  if (appSubdomain === 'specialist') return SPECIALIST_APP_URL;
  if (appSubdomain === 'pay') return PAY_APP_URL;
  if (appSubdomain === 'portal') return PORTAL_APP_URL;
  if (appSubdomain === 'intake') return INTAKE_APP_URL;
  if (appSubdomain === 'validation') return VALIDATION_APP_URL;
  if (appSubdomain === 'onboarding') return ONBOARDING_APP_URL;
  return null;
}

// Client Applications
// Goal is:
// export const ADMIN_APP_URL = process.env.REACT_APP_ADMIN_APP_URL;
export const ADMIN_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_ADMIN_APP_URL as string : getAppUrl('admin');
export const CUSTOMER_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_CUSTOMER_APP_URL as string : getAppUrl('app');
export const SPECIALIST_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_SPECIALIST_APP_URL as string : getAppUrl('specialist');
export const PAY_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_PAY_APP_URL as string : getAppUrl('pay');
export const PORTAL_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_PORTAL_APP_URL as string : getAppUrl('portal');
export const INTAKE_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_INTAKE_APP_URL as string : getAppUrl('intake');
export const VALIDATION_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_VALIDATION_APP_URL as string : getAppUrl('validation');
export const ONBOARDING_APP_URL = (isLocal && !isLocalDev) ? process.env.REACT_APP_LOCAL_ONBOARDING_APP_URL as string : getAppUrl('onboarding');

// Site API
// Goal is:
// export const SITE_API_BASE_URL = process.env.REACT_APP_SITE_API_BASE_URL;
function getSiteApi() {
  if (isLocalDev) {
    return process.env.REACT_APP_SITE_API_BASE_URL_DEV;
  }

  if (isLocalStaging) {
    return process.env.REACT_APP_SITE_API_BASE_URL_STAGING;
  }

  return process.env.REACT_APP_SITE_API_BASE_URL;
}

export const SITE_API_BASE_URL = getSiteApi();
