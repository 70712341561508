import {
  useCallback,
  useState,
} from 'react';
import type { FormEvent } from 'react';

import { Box } from '@m/components';
import { CountryFlagUsa } from '@m/icons';

import { PageButton } from 'apps/kiosk/components';
import { formatPhoneInput } from 'utils/formatPhoneInput';

import {
  Form,
  PhoneValue,
  PhonePlaceholder,
  InputCursor,
} from './PhoneForm.styled';
import { PhoneKeyboard } from './PhoneKeyboard';

type PhoneFormProps = {
  loading?: boolean;
  submitted?: boolean;
  onSubmit: (phoneNumber: string) => void;
  error: boolean;
};

export function PhoneForm({
  loading,
  submitted,
  onSubmit,
  error,
}: PhoneFormProps) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const maxLength = phoneNumber[0] === '1' ? 11 : 10;
  const phoneNumberComplete = phoneNumber.length === maxLength;

  const handleChange = useCallback((text: string) => {
    setPhoneNumber(text.toUpperCase());
  }, []);

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    onSubmit(phoneNumber.replace(/^1/, ''));
  }, [onSubmit, phoneNumber]);

  return (
    <Box
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      flex={1}
    >
      <Form onSubmit={handleSubmit}>
        <PhoneValue>
          {phoneNumber ? (
            <>
              <span>
                {formatPhoneInput(phoneNumber)}
              </span>
              <InputCursor isLoading={loading} />
            </>
          ) : (
            <PhonePlaceholder>
              +1 (000) 000-0000
            </PhonePlaceholder>
          )}
          <CountryFlagUsa
            width={28}
            height={21}
            position="absolute"
            top="50%"
            left="16px"
            marginTop="-11px"
          />
        </PhoneValue>
        <PhoneKeyboard
          maxLength={maxLength}
          onChange={handleChange}
        />
        <PageButton
          disabled={!error && (!phoneNumberComplete || loading || submitted)}
          backgroundColor="blue6"
          color="grey1"
          type="submit"
        >
          Sign Up
        </PageButton>
      </Form>
    </Box>
  );
}
