import { apiFetch } from 'utils/http';

export const OnboardingService = {
  getSiteDetails: (joinUUID: string) => apiFetch(
    `/kiosk/onboarding/${joinUUID}`,
  ),

  userSignup: ({
    uuid,
    phoneNumber,
  }: {
    uuid: string;
    phoneNumber: string;
  }) => apiFetch(
    `/kiosk/onboarding/${uuid}/send`,
    {
      method: 'POST',
      body: { phoneNumber },
    },
  ),
};
