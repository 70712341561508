import styled from 'styled-components';

import { withStyledSystem } from '@m/theme';
import type { StyledSystemProps } from '@m/theme';

export const AlertModalBox = styled.div<{ show: boolean } & StyledSystemProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition-property: opacity, backdrop-filter;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: ${({ show }) => (show ? 0 : 250)}ms;
  backdrop-filter: ${({ show }) => (show ? 'blur(2px)' : 'none')};
  ${withStyledSystem}
`;

type AlertModalContentProps = StyledSystemProps & { show: boolean };

export const AlertModalIconBox = styled.div`
  margin-bottom: 40px;

  @media (max-height: 700px) {
    margin-bottom: 24px;

    & > svg {
      width: 64px;
      height: 64px;
    }
  }

  @media (max-width: 700px) {
    margin-bottom: 24px;

    & > svg {
      width: 64px;
      height: 64px;
    }
  }
`;

export const AlertModalContent = styled.div<AlertModalContentProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 660px;
  height: auto;
  max-width: 100%;
  padding: 24px;
  pointer-events: inherit;
  background-color: ${({ theme }) => (theme.colors.grey1)};
  user-select: none;
  border-radius: 16px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08),
              0 2px 24px rgba(0, 0, 0, 0.08);
  transform: translate3d(
    0,
    ${({ show }) => (show ? '0' : '-100vh')},
    0
  );
  transition-property: transform;
  transition-duration: ${({ show }) => (show ? 500 : 300)}ms;
  transition-timing-function: ease;
  transition-delay: ${({ show }) => (show ? 200 : 0)}ms;

  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-height: 768px) {
    padding-top: 40px;
  }

  @media (min-width: 700px) and (min-height: 700px) {
    border-radius: 32px;
    padding-bottom: 40px;
  }
  ${withStyledSystem}
`;
