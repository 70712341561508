import type { ReactNode } from 'react';

import type { BoxProps } from '@m/components';

import { ReactComponent as PlateIcon } from 'apps/kiosk/assets/svg/icon-plate.svg';

import {
  ConfirmationBox,
  ConfirmationContent,
  ConfirmationIconBox,
  ConfirmationHeading,
  ConfirmationMessage,
  ButtonBox,
  DoneButton,
  HelpButton,
} from './ConfirmationScreen.styled';

interface ConfirmationScreenProps extends BoxProps {
  icon?: ReactNode,
  heading: ReactNode;
  message: ReactNode;
  onDismiss: () => void;
  helpMessage?: ReactNode;
  onHelpClick?: () => void;
}

export function ConfirmationScreen({
  icon = <PlateIcon />,
  heading,
  message,
  onDismiss,
  helpMessage,
  onHelpClick,
  ...boxProps
}: ConfirmationScreenProps) {
  return (
    <ConfirmationBox {...boxProps}>
      <ConfirmationContent>
        <ConfirmationIconBox>
          {icon}
        </ConfirmationIconBox>
        <ConfirmationHeading>
          {heading}
        </ConfirmationHeading>
        <ConfirmationMessage>
          {message}
        </ConfirmationMessage>
      </ConfirmationContent>
      <ButtonBox>
        <DoneButton onClick={onDismiss}>
          Done
        </DoneButton>
        {helpMessage && (
          <HelpButton onClick={onHelpClick}>
            {helpMessage}
          </HelpButton>
        )}
      </ButtonBox>
    </ConfirmationBox>
  );
}
