import styled from 'styled-components';

import { withStyledSystem } from '@m/theme';
import type { StyledSystemProps } from '@m/theme';

export type StyledSlideUpModalProps = StyledSystemProps & {
  show: boolean;
  variant?: 'sheet' | 'overlay';
};

export const SlideUpModalBox = styled.div<StyledSlideUpModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: ${({ variant }) => (variant === 'overlay' ? 24 : 0)}px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: ${({ show }) => (show ? 0 : 300)}ms;

  @media (min-width: 768px) {
    padding-left: ${({ variant }) => (variant === 'overlay' ? 150 : 92)}px;
    padding-right: ${({ variant }) => (variant === 'overlay' ? 150 : 92)}px;
  }

  @media (min-height: 768px) {
    padding-top: ${({ variant }) => (variant === 'overlay' ? 24 : 85)}px;
    padding-bottom: ${({ variant }) => (variant === 'overlay' ? 24 : 0)}px;
  }

  @media (min-height: 1000px) {
    padding-top: ${({ variant }) => (variant === 'overlay' ? 'min(20vh, 128px)' : 'min(25vh, 200px)')};
    padding-bottom: ${({ variant }) => (variant === 'overlay' ? 'min(20vh, 128px)' : 0)};
  }

  @media (min-height: 1200px) {
    padding-top: ${({ variant }) => (variant === 'overlay' ? 'min(25vh, 150px)' : 'max(30vh, 350px)')};
    padding-bottom: ${({ variant }) => (variant === 'overlay' ? 'min(25vh, 150px)' : 0)};
  }

  ${withStyledSystem}
`;

function getTransition({
  show,
  variant = 'sheet',
}: StyledSlideUpModalProps) {
  const baseDuration = show ? 400 : 500;
  const delay = show ? 200 : 50;

  if (variant === 'overlay') {
    return `
      opacity ${baseDuration * (show ? 0.4 : 0.3)}ms linear ${delay * (show ? 1 : 3)}ms,
      transform ${baseDuration}ms ease ${delay}ms
    `;
  }
  return `transform ${baseDuration}ms ease ${delay}ms`;
}
export const SlideUpModalContent = styled.div<StyledSlideUpModalProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${({ variant }) => (variant === 'overlay' ? 800 : 1180)}px;
  height: ${({ variant }) => (variant === 'overlay' ? 'auto' : '100vh')};
  max-width: 100%;
  flex: 1;
  pointer-events: inherit;
  background-color: ${({ theme }) => (theme.colors.grey1)};
  user-select: none;
  overflow: hidden;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-left-radius: ${({ variant }) => (variant === 'overlay' ? 14 : 0)}px;
  border-bottom-right-radius: ${({ variant }) => (variant === 'overlay' ? 14 : 0)}px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08),
              0 2px 24px rgba(0, 0, 0, 0.08);
  transform: translate3d(
    0,
    ${({ show }) => (show ? '0' : '100%')},
    0
  );
  opacity: ${({ show, variant }) => ((show === false && variant === 'overlay') ? 0 : 1)};
  transition: ${getTransition};
  ${withStyledSystem}
`;
