import type { ReactNode } from 'react';

import type { BoxProps } from '@m/components';
import type { StyledSystemProps } from '@m/theme';

import { ReactComponent as DismissIcon } from 'apps/kiosk/assets/svg/dismiss.svg';

import {
  ModalBackground,
  CloseButton,
  CloseButtonContentLarge,
  ModalHeadingLarge,
} from './Modals.styled';
import {
  ScrollingModalBox,
  ScrollingModalContent,
  ScrollingModalHeader,
  ScrollingModalScrollArea,
  ScrollContentBox,
} from './ScrollingModal.styled';

interface ScrollingModalProps extends BoxProps {
  show?: boolean;
  onCancel?: () => void;
  hideCloseButton?: boolean,
  boxProps?: StyledSystemProps
  heading?: ReactNode;
  children?: ReactNode;
}

export function ScrollingModal({
  show = false,
  onCancel = () => {},
  hideCloseButton,
  boxProps,
  heading,
  children,
  ...contentProps
}: ScrollingModalProps) {
  return (
    <ScrollingModalBox
      {...boxProps}
      show={show}
    >
      <ModalBackground onClick={onCancel} />
      <ScrollingModalContent
        {...contentProps}
        show={show}
      >
        <ScrollingModalHeader>
          <ModalHeadingLarge
            flex={1}
            fontSize="32px !important"
            letterSpacing="0.03em"
            marginBottom={0}
            lineHeight="48px !important"
          >
            {heading}
          </ModalHeadingLarge>
          <CloseButton
            onClick={onCancel}
            hide={hideCloseButton}
            position="relative"
            marginRight="16px"
          >
            <CloseButtonContentLarge>
              <DismissIcon
                fill="currentColor"
                width={14}
                height={14}
              />
            </CloseButtonContentLarge>
          </CloseButton>
        </ScrollingModalHeader>
        <ScrollingModalScrollArea show={show}>
          <ScrollContentBox show={show}>
            {children}
          </ScrollContentBox>
        </ScrollingModalScrollArea>
      </ScrollingModalContent>
    </ScrollingModalBox>
  );
}
