import {
  Box,
  Text,
  TextBold,
} from '@m/components';
import { Wordmark } from '@m/icons';

import { PageButton } from 'apps/kiosk/components/Button';

import * as Styled from './LandingScreen.styled';

import type { Site } from 'types/api';

type LandingProps = {
  siteInfo: Site | null;
  onClickJoin: () => void;
  onClickRates: () => void;
  onClickLearnMore: () => void;
};

export function LandingScreen({
  siteInfo,
  onClickJoin,
  onClickRates,
  onClickLearnMore,
}: LandingProps) {
  return (
    <Box
      width="100%"
      height="100%"
      maxHeight="743px"
      flexDirection="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Box
        flex={1}
        width="100%"
        maxWidth="540px"
        maxHeight="743px"
        marginLeft="24px"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        css="user-select: none"
      >
        <Wordmark
          width={104}
          height={32}
          fill="grey7"
          marginTop="40px"
        />
        <Box
          width="100%"
          height="auto"
          flex={0}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          marginBottom="48px"
        >
          <Styled.Heading
            fontSize="56px !important"
            color="blue6"
            lineHeight={1.1}
            marginBottom="30px"
          >
            <div>Pay to park,</div>
            <div>on your phone</div>
          </Styled.Heading>
          <Text
            as="div"
            color="grey7"
            fontSize="20px"
            lineHeight={1.5}
            fontWeight="400"
            marginBottom="80px"
          >
            <div>
              Sign up once for contactless payment forever.
            </div>
            <div>
              Just drive in and drive out to park.
            </div>
            <div>
              No tickets, no waiting.
            </div>
          </Text>
          <Box
            flexDirection="row"
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <PageButton
              width="480px"
              backgroundColor="blue6"
              color="grey1"
              onClick={onClickJoin}
            >
              Start Parking
            </PageButton>
            {/* <PageButton
              width="240px"
              backgroundColor="transparent"
              color="grey7"
              marginLeft="12px"
              fontWeight={500}
              css={`
                &:active {
                  background-color: rgba(0,0,0,0.05);
                }
              `}
              onClick={onClickRates}
            >
              View Rates
            </PageButton> */}
          </Box>
        </Box>
        <Box
          flex={0}
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          marginBottom="40px"
        >
          <TextBold
            fontSize="18px"
            marginRight="6px"
          >
            Questions?
          </TextBold>
          <Text
            as="a"
            fontSize="18px"
            fontWeight={400}
            padding="8px"
            borderRadius="4px"
            css={`
              cursor: pointer;
              text-decoration: underline;

              &:active {
                background-color: rgba(0,0,0,0.05);
                opacity: 0.75;
              }
            `}
            onClick={onClickLearnMore}
          >
            Learn more about how this works
          </Text>
        </Box>
      </Box>
      <Box
        flex={1}
        width="100%"
        maxWidth="570px"
        maxHeight="743px"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        position="relative"
        css="user-select: none"
      >
        <Styled.WelcomeImage />
        <Styled.AppImage>
          {siteInfo && (
            <Styled.SiteInfo>
              <Styled.SiteName>{siteInfo?.name}</Styled.SiteName>
              <Styled.SiteAddress>
                {`${siteInfo?.street}, ${siteInfo?.city}, ${siteInfo?.state}`}
              </Styled.SiteAddress>
            </Styled.SiteInfo>
          )}
        </Styled.AppImage>
      </Box>
    </Box>
  );
}
