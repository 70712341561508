import styled from 'styled-components';

import { fadeIn } from 'styled/keyframes';

export const InputContainerBox = styled.div<{ completed?: boolean | null }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ completed }) => (completed ? 0 : 1)};
  transform: translate3d(
    0,
    ${({ completed }) => (completed ? '-40px' : '0')},
    0
  ) scale3d(
    ${({ completed }) => (completed ? 0.85 : 1)},
    ${({ completed }) => (completed ? 0.85 : 1)},
    1
  );
  transition: opacity 350ms linear 0ms,
              transform 500ms ease 0ms;

  @media (min-height: 700px) {
    padding-top: 48px;
  }

  @media (min-width: 700px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (min-height: 1000px) {
    padding-top: 5vh;
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 60px;
  padding-top: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: 0.02em;
  color: ${({ theme }) => (theme.colors.orange4)};
  user-select: none;
  animation: ${fadeIn} 300ms linear 0ms 1 backwards;
`;
