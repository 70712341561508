import styled from 'styled-components';

type KeyboardContainerProps = {
  active?: boolean;
  initialRevealComplete?: boolean;
  submitEnabled?: boolean;
};

export const KeyboardStyler = styled.div<KeyboardContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 280px;

  .simple-keyboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .simple-keyboard .hg-rows {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .simple-keyboard .hg-row {
    display: grid;
    flex: 1;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;
    position: relative;
    place-items: stretch;
    margin-bottom: min(1.25vmin, 12px);

    &:nth-child(4) {
      & > .hg-button {
        transform: translate3d(calc(100% + 8px), 0, 0);
      }
    }
  }

  .simple-keyboard .hg-button {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 1;
    width: 100%;
    flex: 1;
    align-self: stretch;
    justify-self: stretch;
    flex-grow: 0;
    user-select: none;
    text-transform: uppercase;
    transition: background-color 100ms linear 0ms,
                opacity 150ms linear 0ms;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 4px;
      right: 4px;
      height: 1px;
      background-color: rgba(0,0,0,0.2);
    }
    &.hg-button-backspace {
      background-color: rgba(0,0,0,0);
      font-size: 34px;
      font-weight: 400;
      max-width: none;
      box-shadow: none;
      color: #666;

      &::before {
        display: none;
      }
    }

    &:active {
      background-color: rgba(196, 196, 196, 0.17);
      transition: none !important;
    }
  }
`;
