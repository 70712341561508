import { stringify } from 'query-string';

import { SITE_API_BASE_URL } from 'config/env';

import {
  getAuthToken,
  getDefaultAuthMethod,
} from './apiUtils';
import {
  fetchWorker,
  WorkerizeFetchOptions,
} from './fetchWorker';

import type {
  APIResponse,
  FetchConfig,
  FetchOptions,
} from './types';

export async function apiFetch(
  endpoint: string,
  {
    method = 'GET',
    headers = {},
    params,
    body,
    stringifyOpts,
    sendRawBody,
    useWorker = false,
  }: FetchOptions = {},
  { authMethod = getDefaultAuthMethod() }: FetchConfig = {},
): Promise<APIResponse> {
  const fetchFn = useWorker ? fetchWorker : global.fetch;
  const shouldStringifyBody = (!sendRawBody || useWorker === false);

  const fetchOptions: WorkerizeFetchOptions = {
    method,
    headers,
  };

  if (shouldStringifyBody) {
    fetchOptions.headers['Content-Type'] = 'application/json';
  }

  if (authMethod === 'token') {
    const authToken = await getAuthToken();
    if (authToken) {
      fetchOptions.headers.Authorization = authToken;
    }
  } else if (authMethod === 'cookie') {
    fetchOptions.credentials = 'include';
  }
  if (body && shouldStringifyBody) {
    // Format body as JSON
    fetchOptions.body = JSON.stringify(body);
  } else if (body) {
    // Send body without any modification
    fetchOptions.body = body;
  }

  let query = '';
  if (
    params
    && typeof params === 'object'
    && Object.keys(params).length > 0
  ) {
    query = `?${stringify(params, stringifyOpts)}`;
  }
  const reqUrl = `${SITE_API_BASE_URL}${endpoint}${query}`;

  return fetchFn(reqUrl, fetchOptions).then(async (response) => {
    if (response.status === 200) {
      return response.json();
    }
    const data = await response.json();

    return {
      success: false,
      status: response.status,
      statusText: response.statusText,
      ...(typeof data === 'object' ? data : { error: data }),
    };
  });
}

