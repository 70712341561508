import {
  Box,
  Text,
} from '@m/components';

import { ReactComponent as SmsIcon } from 'apps/kiosk/assets/svg/sms.svg';
import { ReactComponent as WarningIcon } from 'apps/kiosk/assets/svg/warning.svg';
import { AlertModal } from 'apps/kiosk/components/Modals/AlertModal';
import {
  ModalHeadingLarge,
  MessageText,
  ModalButton,
} from 'apps/kiosk/components/Modals/Modals.styled';
import { formatPhoneInput } from 'utils/formatPhoneInput';

type TextMessageHelpProps = {
  show?: boolean;
  phoneNumber: string;
  onRestart?: () => void;
  onDismiss?: () => void;
};

export function TextMessageHelp({
  show,
  phoneNumber,
  onRestart,
  onDismiss,
}: TextMessageHelpProps) {
  return (
    <AlertModal
      show={show}
      onCancel={onDismiss}
      width="660px !important"
    >
      <SmsIcon />
      <ModalHeadingLarge
        marginTop="32px !important"
        letterSpacing="0.03em"
        marginBottom="8px"
      >
        We texted a sign up link to
      </ModalHeadingLarge>
      <ModalHeadingLarge
        color="blue6"
        fontSize="34px !important"
        letterSpacing="0.035em"
      >
        {formatPhoneInput(phoneNumber).replace('+1 ', '')}
      </ModalHeadingLarge>
      <MessageText
        as="p"
        fontSize="22px !important"
        fontWeight={400}
        lineHeight="1.5 !important"
        marginTop="12px"
      >
        <Text fontWeight={500}>Check the strength of your cell signal. </Text>
        If you have a weak signal, proceed to street level
        where cell reception is stronger to receive incoming text messages.
      </MessageText>
      <Box
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        flexWrap="wrap"
        width="100%"
        padding="24px"
        borderRadius="4px"
        backgroundColor="rgba(03,03,03,0.04)"
        marginBottom="48px"
      >
        <WarningIcon width={24} height={24} />
        <Box
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          fontSize="18px"
          color="#151920"
          paddingLeft="12px"
        >
          <Text lineHeight="24px">Is the phone number shown above correct?</Text>
          <Text lineHeight={1.5} fontWeight={400}>
            If not, tap &ldquo;Start over&rdquo; below to re-enter your number.
          </Text>
        </Box>
      </Box>
      <ModalButton
        onClick={onDismiss}
        backgroundColor="blue6"
        color="grey1"
      >
        Done
      </ModalButton>
      <ModalButton
        onClick={onRestart}
        backgroundColor="transparent"
        color="#000"
        fontWeight={500}
        letterSpacing="0.035em"
        marginTop="12px"
        marginBottom="-8px"
        css={`
          &:active {
            background-color: rgba(0,0,0,0.05);
          }
        `}
      >
        Start over
      </ModalButton>
    </AlertModal>
  );
}
