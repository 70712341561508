import styled from 'styled-components';

import { withStyledSystem } from '@m/theme';
import type { StyledSystemProps } from '@m/theme';

import { PageButton } from 'apps/kiosk/components/Button';
import { ModalHeading } from 'apps/kiosk/components/Modals';
import {
  fadeIn,
  fadeInPop,
  slideUpFadeIn,
} from 'styled/keyframes';

export const ConfirmationBox = styled.div<StyledSystemProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  padding-top: 36px;

  @media (min-height: 700px) {
    padding-top: 48px;
  }

  @media (min-width: 700px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (min-height: 1000px) {
    padding-top: 5vh;
  }

  ${withStyledSystem}
`;

export const ConfirmationContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const ConfirmationIconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 120px;
  height: 90px;
  margin-bottom: 24px;
  animation: ${fadeInPop} 500ms ease-out 600ms 1 backwards;
`;

export const ConfirmationHeading = styled(ModalHeading)`
  font-size: 32px;
  text-align: center;
  line-height: 1.3;
  padding: 0 24px;
  max-width: 530px;
  margin-bottom: 36px;
  animation: ${slideUpFadeIn} 700ms ease-out 750ms 1 backwards;
`;

export const ConfirmationMessage = styled.div`
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  max-width: 500px;
  color: #000;
  animation: ${fadeIn} 600ms linear 1250ms 1 backwards;

  & p {
    margin-top: 0;
    margin-bottom: 24px;
  }
  & p:last-child {
    margin-bottom: 0;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
`;

export const DoneButton = styled(PageButton)`
  background-color: ${({ theme }) => (theme.colors.blue6)};
  color: ${({ theme }) => (theme.colors.grey1)};
  animation: ${slideUpFadeIn} 500ms ease-out 1500ms 1 backwards;
`;

export const HelpButton = styled(PageButton)`
  margin-top: 8px;
  color: #000 !important;
  font-weight: 400;
  letter-spacing: 0.025em;
  animation: ${slideUpFadeIn} 500ms ease-out 1600ms 1 backwards;

  &:active {
    background-color: rgba(0,0,0,0.05);
  }
`;
