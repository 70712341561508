import {
  Box,
  Text,
} from '@m/components';

import { ReactComponent as MarkerIcon } from 'apps/kiosk/assets/svg/p-marker.svg';
import { ScrollingModal } from 'apps/kiosk/components';

import type { Site } from 'types/api';

interface SiteInfoContainerProps {
  site: Site | null;
  show?: boolean;
  onDismiss?: () => void;
}
export function SiteInfoContainer({
  site,
  show,
  onDismiss,
}: SiteInfoContainerProps) {
  return (
    <ScrollingModal
      show={show}
      onCancel={onDismiss}
      heading={site?.name}
    >
      {site && (
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          height="104px"
          borderBottom="0.5px solid #CDCACA"
          flexShrink={0}
        >
          <MarkerIcon />
          <Text
            fontSize="20px"
            fontWeight="400"
            flex={1}
            textAlign="left"
            paddingLeft="16px"
          >
            {`${site.street}, ${site.city}, ${site.state}`}
          </Text>
        </Box>
      )}
    </ScrollingModal>
  );
}
