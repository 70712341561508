import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme, { WebFonts } from '@metropolis-io/theme';

import { setDefaultAuthMethod } from 'utils/http';

import { GlobalStyles } from './Kiosk.styled';
import OnboardingKiosk from './OnboardingKiosk';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export function OnboardingApp() {
  return (
    <ThemeProvider theme={theme}>
      <WebFonts />
      <BrowserRouter>
        <OnboardingKiosk />
      </BrowserRouter>
      <GlobalStyles />
    </ThemeProvider>
  );
}
