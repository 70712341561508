import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import storage from 'local-storage-fallback';
import throttle from 'lodash/throttle';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  IdleBanner,
  PageRoot,
  PageContent,
  SlideUpModal,
} from 'apps/kiosk/components';
import {
  AccountExists,
  HowThisWorks,
  LandingScreen,
  LinkSent,
  TextMessageHelp,
} from 'apps/kiosk/components/Onboarding';
import { ONBOARDING_UUID_STORAGE_KEY } from 'apps/kiosk/constants';
import { PhoneInputContainer } from 'apps/kiosk/containers/Onboarding/PhoneInputContainer';
import { SiteInfoContainer } from 'apps/kiosk/containers/Onboarding/SiteInfoContainer';
import { OnboardingService } from 'apps/kiosk/services';
import Countdown from 'components/Countdown';
import {
  heapAddEventProperties,
  heapTrackProperties,
} from 'utils/heap';

import type { Site } from 'types/api';

// Show banner after 10 seconds
const INITIAL_IDLE_TIMEOUT = 10;
const EXTEND_TIME_MULTIPLIER = 1.5;

export function LandingContainer() {
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();

  const [siteInfo, setSiteInfo] = useState<Site | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showLinkSent, setShowLinkSent] = useState(false);
  const [showAccountExists, setShowAccountExists] = useState(false);
  const [showTextMessageHelp, setShowTextMessageHelp] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showSiteInfo, setShowSiteInfo] = useState(false);
  const [idleTimeout, setIdleTimeout] = useState(INITIAL_IDLE_TIMEOUT);
  const [showIdleBanner, setShowIdleBanner] = useState(false);

  const renderIdleCountdown = (
    showModal
    || showLinkSent
    || showAccountExists
    || showTextMessageHelp
    || showLearnMore
    || showSiteInfo
  );

  const openModal = useCallback(() => {
    setShowModal(true);
    setShowPhoneInput(true);
    heapTrackProperties('Open: Signup Modal', {
      'Date/Time (UTC)': (new Date()).toUTCString(),
      timestamp: Date.now(),
    });
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setShowTextMessageHelp(false);
    setShowLearnMore(false);
    setShowSiteInfo(false);

    if (!showLinkSent && !showAccountExists) {
      heapTrackProperties('Close: Signup Modal (Incomplete)', {
        'Date/Time (UTC)': (new Date()).toUTCString(),
        timestamp: Date.now(),
      });
    } else if (showLinkSent) {
      heapTrackProperties('Close: Signup Modal (Complete) [new-user]', {
        'Date/Time (UTC)': (new Date()).toUTCString(),
        timestamp: Date.now(),
      });
    } else if (showAccountExists) {
      heapTrackProperties('Close: Signup Modal (Complete) [existing-user]', {
        'Date/Time (UTC)': (new Date()).toUTCString(),
        timestamp: Date.now(),
      });
    }
    setTimeout(() => {
      setPhoneNumber('');
      setShowPhoneInput(false);
      setShowLinkSent(false);
      setShowAccountExists(false);
    }, 350);
  }, [showAccountExists, showLinkSent]);

  const resetIdleTimeout = useMemo(
    // Using throttle to prevent duplicate calls
    // if both mouse/touch events fire
    () => throttle(
      () => {
        // The `<Countdown />` component is assigned
        // a `key` prop referencing the duration
        // This forces the component to re-render
        // in a fresh state, having the effect
        // of resetting the idle timer
        setIdleTimeout(Infinity);
        setIdleTimeout(INITIAL_IDLE_TIMEOUT);
      },
      5000,
      { leading: true, trailing: true },
    ),
    [],
  );

  const extendIdleTimeout = useCallback(() => {
    if (showIdleBanner) {
      setIdleTimeout((timeout) => (
        Math.round(timeout * EXTEND_TIME_MULTIPLIER)
      ));
      setShowIdleBanner(false);
    }
  }, [showIdleBanner]);

  const resetToInitialLanding = useCallback(() => {
    setIdleTimeout(INITIAL_IDLE_TIMEOUT);
    setShowIdleBanner(false);
    closeModal();
  }, [closeModal]);

  const handleLinkSent = useCallback((phone: string) => {
    setPhoneNumber(phone);
    setShowAccountExists(false);
    setShowLinkSent(true);
  }, []);
  const handleAccountExists = useCallback((phone: string) => {
    setPhoneNumber(phone);
    setShowAccountExists(true);
    setShowLinkSent(false);
  }, []);

  useEffect(() => {
    if (!renderIdleCountdown && idleTimeout !== INITIAL_IDLE_TIMEOUT) {
      setIdleTimeout(INITIAL_IDLE_TIMEOUT);
    }
  }, [idleTimeout, renderIdleCountdown]);

  useEffect(() => {
    async function getValidationInfo() {
      const { success, data } = await OnboardingService.getSiteDetails(uuid);
      if (success) {
        setSiteInfo(data);
        storage.setItem(ONBOARDING_UUID_STORAGE_KEY, uuid);
      } else {
        storage.removeItem(ONBOARDING_UUID_STORAGE_KEY);
        history.replace('/');
      }
    }
    getValidationInfo();
    heapAddEventProperties({ 'Site UUID': uuid });
  }, [history, uuid]);

  useEffect(() => {
    if (siteInfo) {
      heapAddEventProperties({
        'Site Id': siteInfo.id,
        'Site Name': siteInfo.name,
      });
    }
  }, [siteInfo]);

  useLayoutEffect(() => {
    if (renderIdleCountdown) {
      window.addEventListener('mousemove', resetIdleTimeout);
      window.addEventListener('touchstart', resetIdleTimeout);
    }
    return () => {
      window.removeEventListener('mousemove', resetIdleTimeout);
      window.removeEventListener('touchstart', resetIdleTimeout);
    };
  }, [resetIdleTimeout, renderIdleCountdown]);

  return (
    <>
      <IdleBanner
        show={showIdleBanner}
        onUserInteract={extendIdleTimeout}
        onIdleTimeout={resetToInitialLanding}
      />
      <PageRoot>
        <PageContent
          padding="0 !important"
        >
          <LandingScreen
            siteInfo={siteInfo}
            onClickJoin={openModal}
            onClickRates={() => setShowSiteInfo(true)}
            onClickLearnMore={() => setShowLearnMore(true)}
          />
        </PageContent>
      </PageRoot>
      <SlideUpModal
        variant="overlay"
        show={showModal && !showTextMessageHelp}
        onCancel={closeModal}
        maxWidth="680px"
        maxHeight="820px"
      >
        {showPhoneInput && (
          <PhoneInputContainer
            siteUUID={uuid}
            completed={showLinkSent || showAccountExists}
            onLinkSent={handleLinkSent}
            onAccountExits={handleAccountExists}
          />
        )}
        {showAccountExists && (
          <AccountExists
            phoneNumber={phoneNumber}
            onDismiss={closeModal}
          />
        )}
        {showLinkSent && (
          <LinkSent
            phoneNumber={phoneNumber}
            onDismiss={closeModal}
            onHelpClick={() => {
              setShowTextMessageHelp(true);
            }}
          />
        )}
      </SlideUpModal>
      <HowThisWorks
        show={showLearnMore}
        onDismiss={() => setShowLearnMore(false)}
      />
      <TextMessageHelp
        show={showTextMessageHelp}
        phoneNumber={phoneNumber}
        onDismiss={() => {
          setShowTextMessageHelp(false);
          closeModal();
        }}
        onRestart={() => {
          setShowModal(false);
          setShowTextMessageHelp(false);
          setShowLinkSent(false);
          setShowAccountExists(false);
          setTimeout(() => {
            setShowPhoneInput(false);
            openModal();
          }, 300);
        }}
      />
      <SiteInfoContainer
        site={siteInfo}
        show={showSiteInfo}
        onDismiss={() => setShowSiteInfo(false)}
      />
      {renderIdleCountdown && !showIdleBanner && (
        <Countdown
          key={`idle-countdown__${idleTimeout}`}
          duration={idleTimeout}
          onComplete={() => setShowIdleBanner(true)}
        />
      )}
    </>
  );
}
